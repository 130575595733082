@import "@purplefish-ai/shadcn-ui/styles/globals.css";

/**
 * This is a workaround to fix the scrollbar issue.
 * It's a known issue with shadcn-ui and the scrollbar is removed when using Select.
 * Temporarily fix it by adding this CSS.
 * https://github.com/radix-ui/primitives/issues/1496#issuecomment-1172107676
 * https://github.com/shadcn-ui/ui/issues/4227#issuecomment-2438290165
 */
html body[data-scroll-locked] {
  overflow: visible !important;
  margin-right: 0 !important;
}
